<template>
    <div class="card mt-2">
        <form class="m-5">
            <div class="row">
                <div class="form-group col">
                    <label>{{label_code}}*</label>
                    <el-input v-model="form.code" disabled></el-input>
                </div>
                <div class="form-group col">
                    <label>{{label_name}}*</label>
                    <el-input v-model="form.nom"></el-input>
                </div>
            </div>
            <div class="row">
                <div class="form-group col" style="display:flex; flex-direction: column;">
                    <label>{{label_act_promo}}</label>
                    <el-switch v-model="form.is_promo" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" class="mt-1"></el-switch>
                </div>
                <div class="form-group col">
                    <label>{{label_price}}</label>
                    <el-input v-model="form.prix"></el-input>
                </div>
                <div class="form-group col">
                    <label>{{label_promo_price}}</label>
                    <el-input v-model="form.prix_promo"></el-input>
                </div>
            </div>
            <div class="row">
                <div class="form-group col">
                    <label>{{label_profile}}</label>
                    <input type="file" @change="onFileChange" id="image">
                </div>
            </div>
            <div class="row">
                <div class="form-group col">
                    <label>{{label_description}}</label>
                    <el-input type="textarea" v-model="form.description" maxlength="140" show-word-limit></el-input>
                </div>
            </div>
            
            
            <el-button type="primary" class="mt-5" @click="handlesave">{{label_save}}</el-button>
        </form>

    </div>
</template>

<script>

import axios from 'axios';
// import moment from 'moment'; 
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export default {
    
    props: ['form','initData','onClose'],
    data() {
        return {

            // lang
            label_name: this.$t('pages.services.services_add.name'),
            label_price: this.$t('pages.services.services_add.price'),
            label_promo_price: this.$t('pages.services.services_add.promo_price'),
            label_act_promo: this.$t('pages.services.services_add.act_promo'),
            label_code: this.$t('pages.services.services_add.code'),
            label_description: this.$t('pages.services.services_add.description'),
            label_profile: this.$t('pages.services.services_add.profile'),
            label_save: this.$t('pages.services.services_add.save'),

        }
    },
    
    created() {
        
    },


    methods: {

        async handlesave() {

            NProgress.start();

            this.form.tags = this.form.nom+" / "+this.form.code+" / "+this.form.description+" / "+this.form.pressing_nom+" / "+this.form.prix_promo+" / "+this.form.prix+" / ";


            try {

              await axios.post('xpress/service/update', this.form);
              this.$notify({
                    type: 'success',
                    title: this.$t('save')
                });

            } 
            catch (error) {
                  this.$notify({
                    type: 'error',
                    title: this.$t('save-error')
                });
            }

            this.onClose();
            this.initData();
            NProgress.done();
        },

        onFileChange(event) {
          this.form.image = event.target.files[0];
        },
    },
}
</script>