<template>
<div class="ml-5  mt-5">

    <h2>{{title}}</h2>
    <div class="mt-5">
          <el-table :data="all_services.filter(data => !search || data.tags.toLowerCase().includes(search.toLowerCase()))" stripe size="medium" style="width: 100% cursor:pointer">
              <el-table-column :label="title_name" prop="nom">
              </el-table-column>
              <el-table-column :label="title_price" prop="prix">
              </el-table-column>
              <el-table-column :label="title_promo_price" prop="prix_promo">
              </el-table-column>
              <el-table-column :label="title_act_promo">
                <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.is_promo"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  active-value="1"
                  inactive-value="0"
                  :change="handleChange(scope)">
                </el-switch>
              </template>
              </el-table-column>
              <el-table-column :label="title_code" prop="code" >
              </el-table-column>
              <el-table-column :label="title_desc" prop="description" width="350">
              </el-table-column>

              <el-table-column align="right">

                  <template slot="header" slot-scope="scope">
                      <el-input  v-model="search"  size="mini" :placeholder="title_search"/>
                  </template>
                  <template slot-scope="scope">
                      <!-- <el-button size="mini" @click="handleEdit(scope.row)">Editer</el-button>
                      <el-button  size="mini" type="danger" @click="handleDelete(scope.row)">Supprimer</el-button> -->
                        <el-button icon="el-icon-edit" @click="handleEdit(scope.row)" circle></el-button>
                        <el-button type="danger" icon="el-icon-delete" @click="handleDelete(scope.row)" circle></el-button>
                  </template>
              </el-table-column>
          </el-table>
    </div>


     <!-- Card edit -->
    <ui-modal ref="edit" size="large" title="Ajouter un nouveau service">
        <EditServComponent v-bind:form="form" v-bind:initData="initData" v-bind:onClose="onClose"/>
    </ui-modal>
</div>
</template>
<script>

    import axios from 'axios';
    // import moment from 'moment'; 
    import NProgress from 'nprogress';
    import 'nprogress/nprogress.css';
    import EditServComponent from '@/components/dashboard/services/EditServComponent.vue';


    export default {

        components: {
            EditServComponent
        },

        data() {
            return {
                // lang
                title: this.$t('pages.services.services_consult.title'),
                title_name: this.$t('pages.services.services_consult.header.name'),
                title_price: this.$t('pages.services.services_consult.header.price'),
                title_promo_price: this.$t('pages.services.services_consult.header.promo_price'),
                title_act_promo: this.$t('pages.services.services_consult.header.act_promo'),
                title_code: this.$t('pages.services.services_consult.header.code'),
                title_desc: this.$t('pages.services.services_consult.header.description'),
                title_search: this.$t('pages.services.services_consult.header.search'),
                // var

                all_services : [],
                search: '',
                form : {
                    code: "",
                    description: "",
                    id: null,
                    is_promo: null,
                    nom: "",
                    pressing_id: null,
                    pressing_nom: "",
                    prix: null,
                    prix_promo: null,
                    image: "",
                    tags: "",
                },
            }
        },

        created() {
            this.initData()
        },

        methods: {

            async initData() {

                NProgress.start();

                try {

                    const response = await axios.get('xpress/service/getAll');
                    var service = response.data.services;
                    
                    service.forEach(element => {
                        element.is_promo = element.is_promo.toString();
                        var act_promo = element.is_promo;
                        element['act_promo'] = act_promo;
                    });

                    this.all_services = service;
                } 
                catch (error) {
                        this.$notify({
                        type: 'error',
                        title: "Echec de recuperation"
                    });
                }

                
                NProgress.done();
            },

            handleChange(data) {

                this.all_services.forEach(element => {

                    if (element.id == data.row.id) {
                        if (element.act_promo === data.row.is_promo) {
                        console.log("equal");
                        }
                        else {
                        this.updateService(data.row);
                        }
                    }
                });

            },

            handleEdit(data){
                this.$refs['edit'].open();
                this.form = data;
            },

            handleDelete(data){

                this.$confirm(this.$t('delete'), {
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('cancel'),
                    type: 'warning'
                    }).then(() => {

                        NProgress.start();

                        axios.post('xpress/service/delete', data);
                        this.$notify({
                            title: this.$t('deleted'),
                            type: 'success'
                        });

                    this.initData();

                    }).catch(() => {
                    this.$notify({
                        title: this.$t('deleted_error'),
                        type: 'error'
                    });

                    NProgress.done();       
                });

            },

            onClose(){
                this.$refs['edit'].close();
            },

            async updateService(data) {

                NProgress.start();
                try {

                    await axios.post('xpress/service/update', data);
                    this.$notify({
                        type: 'success',
                        title: "Enregistrer"
                    });

                } 
                catch (error) {
                        this.$notify({
                        type: 'error',
                        title: "Echec d'enregistrement"
                    });
                }
                this.initData();
                NProgress.done();
            },
        
        },
    }
</script>